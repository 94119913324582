import moment from 'moment'
import { AnyAction } from 'redux'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { ReportsApi } from '@/api/sd/reportsApi'
import { ItemsReportMenu } from '@/api/sd/reportsApi/types'
import { INITIAL_LOCALE } from '@/constants/internalization'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { DATE_FORMAT } from '@/constants/time'
import { reportBYId } from '@/mocks/reports/reportById'
import { AppState } from '@/store/reducers'
import { actions } from '@/store/sd/reports/actions'
import {
  Language,
  Parameters,
  ParametersItems,
  ReportSaveRequest,
  SubscribeItems,
} from '@/types/sd/reports/reports'
import { PopUpService } from '@/utils/services/popUpService'

import reports from '.'

function getReportValue(parameters: any[], key: string): string {
  const param = parameters.find((m: any) => m.name === key)
  if (param) return param.value
  return ''
}

const reportNameReplaceRegexp = /\[.+?\]/g

function reportsMenuItemNameReplace(node: any): void {
  if (node.children) {
    for (let i = 0; i < node.children.length; i++) {
      const child = node.children[i]
      reportsMenuItemNameReplace(child)
      child.name = child.name.replace(reportNameReplaceRegexp, '').trim()
    }
  }

  if (node.name) {
    node.name = node.name.replace(reportNameReplaceRegexp, '').trim()
  }
}

function* getAllReportMenu({
  payload,
}: AnyAction): Generator<unknown, void, Array<ItemsReportMenu>> {
  try {
    const res = yield call(ReportsApi.getReportsMenuItems, payload)

    if (res && res.length > 0) {
      res.map(item => {
        reportsMenuItemNameReplace(item)
      })
    }

    yield put(actions.getReportsMenuItemsResponse(res))
  } catch (error) {
    yield put(actions.getReportsMenuItemsResponseFall(error.message))
  }
}

function* getLazyReportMenu(): Generator<unknown, void, Array<ItemsReportMenu>> {
  try {
    const res = yield call(ReportsApi.getReportsLazyMenuItems)

    if (res && res.length > 0) {
      res.map(item => {
        reportsMenuItemNameReplace(item)
      })
    }

    yield put(actions.getReportsLazyMenuItemsResponse(res))
  } catch (error) {
    yield put(actions.getReportsMenuItemsResponseFall(error.message))
  }
}

function* getMyReportMenu({
  payload,
}: AnyAction): Generator<unknown, void, Array<ItemsReportMenu>> {
  try {
    const res = yield call(ReportsApi.getMyReportsMenu, payload.type, payload.status)

    yield put(actions.getMyReportsMenuResponse(res))
  } catch (error) {
    yield put(actions.getReportsMenuItemsResponseFall(error.message))
  }
}

function* getAllReports({ payload }: AnyAction): Generator<unknown, void, Array<SubscribeItems>> {
  try {
    const res = yield call(ReportsApi.getReportSubscribe, payload)

    if (res && res.length > 0) {
      res.map(item => {
        item.type = item.type.replace(reportNameReplaceRegexp, '').trim()
      })
    }
    yield put(actions.getSubscribeReportsResponse(res))
  } catch (error) {
    yield put(actions.getReportsMenuItemsResponseFall(error.message))
  }
}

function* getReport({ payload }: AnyAction): Generator<unknown, void, typeof reportBYId> {
  try {
    const res = yield call(ReportsApi.getReportById, payload)
    yield put(actions.getReportByIdResponse(res))
  } catch (error) {
    yield put(actions.getReportByIdResponseFall(error.message))
  }
}
function* getDeleteReport({ payload }: AnyAction): Generator<unknown, void, any> {
  try {
    yield call(ReportsApi.deleteReport, payload.id)
    yield put(actions.getDeleteReportResponse(payload.id))
    PopUpService.showGlobalPopUp(
      `${payload.reportsName} ${payload.translate(`translate#title.deleteReport`)}`,
      'success',
    )
  } catch (error) {
    yield put(actions.getDeleteReportResponseFall(error.message))
  }
}

function* getDeleteLazyReport({ payload }: AnyAction): Generator<unknown, void, any> {
  try {
    yield call(ReportsApi.deleteLazyReport, payload.id)
    yield put(actions.getDeleteLazyReportResponse(payload.id))
    PopUpService.showGlobalPopUp(
      `${payload.reportsName} ${payload.translate(`translate#title.deleteReport`)}`,
      'success',
    )
  } catch (error) {
    yield put(actions.getDeleteReportResponseFall(error.message))
  }
}

function* getReportsConfig({ payload }: AnyAction): Generator<unknown, void, Parameters> {
  try {
    const res = yield call(ReportsApi.getReportsConfig, payload)
    yield put(actions.getReportsSubscriptionConfigResponse(res))
  } catch (error) {
    yield put(actions.getReportsSubscriptionConfigResponseFall(error.message))
  }
}

function* saveReport({ payload, translate }: AnyAction): Generator<unknown, void, any> {
  const config = yield select((state: AppState) => state.reports.ReportsConfig?.parameters)
  const uId = yield select((state: AppState) => state.reports.reportUid)
  const id = yield select((state: AppState) => state.reports.reportId)
  const subscriptionId = yield select((state: AppState) => state.reports.subscriptionId)
  const subscriptionName = yield select((state: AppState) => state.reports.subscriptionName)
  const userId = yield sessionStorage.getItem(LocalStorageItems.UserID) || ''
  const lang = yield sessionStorage.getItem(LocalStorageItems.Locale) || INITIAL_LOCALE
  const keyPayload = payload.periodSubscribe
  const validFromValue = payload.dateRange[0]
    ? moment.utc(payload.dateRange[0]).format(DATE_FORMAT) + 'T23:59:59.999Z'
    : undefined
  const validToValue = payload.dateRange[1]
    ? moment.utc(payload.dateRange[1]).format(DATE_FORMAT) + 'T23:59:59.999Z'
    : undefined

  const {
    saveReport,
    reportName,
    subscribe,
    dateRange,
    interval,
    targetFormat,
    showDataReport,
    showLazyReport,
  } = payload
  const report: ReportSaveRequest = {
    parameters: config.reduce((acc: Array<ParametersItems>, item: ParametersItems) => {
      if (payload[item.name] && item.allowArrayOfFilterValues) {
        acc.push({ ...item, value: payload[item.name].join(), parameterDataValues: [] })
      } else {
        acc.push({
          ...item,
          value:
            item.name === 'language'
              ? Language[lang]
              : item.name === 'userId' || item.name === 'userid'
              ? userId
              : payload[item.name],
          parameterDataValues: [],
        })
      }
      return acc
    }, []),
    reportId: id,
    reportUid: uId,
    period: { [keyPayload]: payload[keyPayload] },
    saveReport: !!reportName || saveReport,
    fileFormat: targetFormat || 'XLS',
    reportName: reportName || subscriptionName,
    subscribe: subscribe,
    validFrom: validFromValue,
    validTo: validToValue,
    reportSubscriptionDateInterval: Number(interval),
    subscriptionId: subscriptionName === reportName ? subscriptionId : null,
  }
  try {
    if (showLazyReport) {
      if (showDataReport) {
        const res = yield call(ReportsApi.saveReporDataLazy, report)
      } else {
        const res = yield call(ReportsApi.saveReportLazy, report)
      }

      PopUpService.showGlobalPopUp(
        `${reportName} ${translate(`translate#title.saveReport`)}`,
        'success',
      )
      yield put(actions.saveReportResponse(''))
    } else {
      if (reportName) {
        const res = yield call(ReportsApi.saveReport, report)
        yield put(actions.setParametersReportForDownloadFile(report))
        PopUpService.showGlobalPopUp(
          `${reportName} ${translate(`translate#title.saveReport`)}`,
          'success',
        )
        yield put(actions.saveReportResponse(res))
      } else {
        if (showDataReport) {
          const resData = yield call(ReportsApi.getDataWithoutSave, report)
          yield put(actions.setParametersReportForDownloadFile(report))
          yield put(actions.saveDataReportResponse(resData.data))
        } else {
          const res = yield call(ReportsApi.getHTMLWithoutSave, report)
          yield put(actions.setParametersReportForDownloadFile(report))
          yield put(actions.saveReportResponse(res))
        }
      }
    }
  } catch (error) {
    yield put(actions.saveReportResponseFall(error.message))
  }
}

function* getHTMLReport({ payload }: AnyAction): Generator<unknown, void, Parameters> {
  try {
    const res = yield call(ReportsApi.getHTMLReport, payload)
    yield put(actions.saveReportResponse(res))
  } catch (error) {
    yield put(actions.saveReportResponseFall(error.message))
  }
}

function* getHTMLReportLazy({ payload }: AnyAction): Generator<unknown, void, Parameters> {
  try {
    const res = yield call(ReportsApi.getReportLazy, payload)
    yield put(actions.saveReportResponse(res))
  } catch (error) {
    yield put(actions.saveReportResponseFall(error.message))
  }
}

function* getDataReportLazy({ payload }: AnyAction): Generator<unknown, void, any> {
  try {
    const res = yield call(ReportsApi.getReportLazy, payload)
    yield put(actions.saveDataReportResponse(res.data))
    // yield put(actions.saveReportResponse(''))
  } catch (error) {
    yield put(actions.saveReportResponseFall(error.message))
  }
}

export default function*(): Generator {
  yield takeLatest('@/GET_SUBSCRIBE_REPORTS_REQUEST', getAllReports)
  yield takeLatest('@/GET_REPORTS_MENU_ITEMS_REQUEST', getAllReportMenu)
  yield takeLatest('@/GET_REPORTS_LAZY_MENU_ITEMS_REQUEST', getLazyReportMenu)
  yield takeLatest('@/GET_REPORTS_MY_MENU_ITEMS_REQUEST', getMyReportMenu)
  yield takeLatest('@GET_REPORT_REQUEST_BY_ID', getReport)
  yield takeLatest('@GET_DELETE_REPORT_REQUEST', getDeleteReport)
  yield takeLatest('@GET_DELETE_LAZY_REPORT_REQUEST', getDeleteLazyReport)
  yield takeLatest('@GET_REPORTS_CONFIG_REQUEST', getReportsConfig)
  yield takeLatest('@SAVE_REPORT_LAZY_REQUEST', getHTMLReportLazy)
  yield takeLatest('@SAVE_REPORT_DATA_LAZY_REQUEST', getDataReportLazy)
  yield takeLatest('@SAVE_REPORT_REQUEST', saveReport)
  yield takeLatest('@GET_HTML_REPORT_REQUEST', getHTMLReport)
}
