import IconButton from '@material-ui/core/IconButton'
import TableCell from '@material-ui/core/TableCell'
import MuiTableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import DotIcon from '@material-ui/icons/FiberManualRecord'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import clsx from 'clsx'
import moment from 'moment'
import React, { ReactElement, SyntheticEvent, useCallback, useState } from 'react'

import { DEFAULT_DATE_TIME_FORMAT } from '@/constants/time'
import { TreeTableItemData } from '@/types/sd/tickets/ticket'
import { getDocStatusColor } from '@/utils/colors'

import Collapse from '../Collapse'
import { useClasses } from './styles'
import { Props } from './types'

const TableRow = ({
  row,
  headers,
  children,
  order,
  orderBy,
  nesting,
  ticketClick,
  posTicketClick,
}: Props): ReactElement => {
  const [collapse, setCollapse] = useState<boolean>(false)
  const classes = useClasses({ collapse })

  const handleCollapse = useCallback((event: SyntheticEvent) => {
    setCollapse(collapse => !collapse)
    event.stopPropagation()
  }, [])

  const handleRowClick = useCallback(
    (ticketInfo: any) => (): void => {
      if (row.docnr.includes('PSR') && posTicketClick) {
        return posTicketClick(ticketInfo)
      }

      if (ticketClick) {
        return ticketClick(ticketInfo)
      }

      return undefined
    },
    [row, posTicketClick, ticketClick],
  )

  return (
    <>
      <MuiTableRow
        onClick={handleRowClick(
          children && children.length ? { item: row, childItems: children } : row,
        )}
        className={clsx({ [classes.clickable]: posTicketClick || ticketClick })}
      >
        {headers.map((key: { label: string; name: string }, index: number) => {
          if (key.name === 'receivedate') {
            return (
              <TableCell key={headers[index].name} className={classes.cell}>
                <Typography variant="body2">
                  {moment(row[key.name]).format(DEFAULT_DATE_TIME_FORMAT)}
                </Typography>
              </TableCell>
            )
          }
          if (key.name === 'docnr') {
            return (
              <TableCell
                key={headers[index].name}
                align="left"
                className={classes.cell}
                style={{ paddingLeft: `${20 * nesting + 12}px` }}
              >
                <div
                  className={clsx(classes.wrapper, {
                    [classes.withBlueColor]: posTicketClick || ticketClick,
                    [classes.withAdditionalPadding]: children && !children.length,
                  })}
                >
                  {row[key.name].includes('PSR') && children && !!children.length && (
                    <IconButton size="small" onClick={handleCollapse} className={classes.arrowIcon}>
                      {collapse ? (
                        <KeyboardArrowDownIcon className={classes.iconSize} />
                      ) : (
                        <KeyboardArrowRightIcon className={classes.iconSize} />
                      )}
                    </IconButton>
                  )}
                  <Typography variant="body2">{row[key.name]}</Typography>
                </div>
              </TableCell>
            )
          }
          if (key.name === 'docstatus') {
            const docStatusColors = getDocStatusColor(row.docstatusid)
            return (
              <TableCell key={headers[index].name} align="left" className={classes.cell}>
                <div
                  className={clsx(classes.statusWrapper)}
                  style={{ background: docStatusColors.backgroundColor }}
                >
                  <DotIcon
                    className={clsx(classes.cellIcon, classes.iconSize)}
                    style={{ color: docStatusColors.iconColor }}
                  />
                  <Typography variant="body2">{row[key.name]}</Typography>
                </div>
              </TableCell>
            )
          }
          if (key.name === 'sladeadline' && row[key.name] !== null) {
            return (
              <TableCell key={headers[index].name} className={classes.cell}>
                <div className={classes.cellWrapper}>
                  <DotIcon
                    className={clsx(
                      classes.cellIcon,
                      {
                        [classes.deadlineGreen]:
                          row.sladeadlinestateid === '1' || row.sladeadlinestateid === '2',
                        [classes.deadlineYellow]: row.sladeadlinestateid === '3',
                        [classes.deadlineRed]: row.sladeadlinestateid === '4',
                      },
                      classes.iconSize,
                    )}
                  />
                  <Typography variant="body2">
                    {moment(row[key.name]).format('YYYY-MM-DD HH:mm:ss')}
                  </Typography>
                </div>
              </TableCell>
            )
          }
          if (row[key.name] !== undefined) {
            return (
              <TableCell key={headers[index].name} className={classes.cell}>
                <Typography variant="body2">{row[key.name]}</Typography>
              </TableCell>
            )
          }
        })}
      </MuiTableRow>
      <Collapse isOpen={collapse}>
        {children &&
          !!children.length &&
          children.map((child: TreeTableItemData, index: number) => (
            <TableRow
              key={`${row.docnr}${child.docnr}${index}`}
              row={child}
              headers={headers}
              order={order}
              orderBy={orderBy}
              nesting={nesting + 1}
              posTicketClick={posTicketClick}
              ticketClick={ticketClick}
            />
          ))}
      </Collapse>
    </>
  )
}

export default TableRow
