import { Checkbox, TextField } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import DotIcon from '@material-ui/icons/FiberManualRecord'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import clsx from 'clsx'
import moment from 'moment'
import React, { ReactElement, SyntheticEvent, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import PhotoCamera from '@/assets/atmeye/icons/photo_camera.svg'
import VideoCamera from '@/assets/atmeye/icons/video_camera.svg'
import Collapse from '@/components/blocks/Collapse'
import FolderIconSvg from '@/components/controls/Icon'
import { DEFAULT_DATE_TIME_SEC_FORMAT } from '@/constants/time'
import { statusIconActionLogTable } from '@/constants/usm/usmModule'
import { getDeviceStateColor, getDocStatusColor } from '@/utils/colors'

import { StyledState, StyledTableRow, useClasses } from './styles'
import { Props } from './types'

const stateNames = [
  'alarms_count',
  'connection_state',
  'core_state',
  'license_state',
  'technical_events_count',
]

const ItemRow = ({
  row,
  rows,
  headers,
  nesting,
  order,
  orderBy,
  ticketClick,
  onItemEditClick,
  posTicketClick,
  disableRowClick,
  parentIndex,
  rowIndex,
  editTableCellRequest,
  incomingHeaders,
  selectableTable,
  checkboxColumnsNames,
  handleCheckedRows,
  handleCheckedObjectRows,
  selectedSecurityObjectRows,
  selectedSecurityNetworksRows,
  setCheckedGroup,
  isSelectAll,
  setIsIndeterminate,
}: Props): ReactElement => {
  const [collapse, setCollapse] = useState<boolean>(false)
  const [status, statusClicker] = useState<string | null>(null)
  const [note, changeNote] = useState<string>('')
  const dispatch = useDispatch()

  const classes = useClasses()

  const handleCollapse = useCallback((event: SyntheticEvent) => {
    setCollapse(collapse => !collapse)
    event.stopPropagation()
  }, [])

  const handleRowClick = useCallback(
    (ticketInfo: any) => (): void => {
      if (selectableTable) {
        return
      }
      if (row.item.docnr && row.item.docnr.includes('PSR') && posTicketClick) {
        return posTicketClick(ticketInfo)
      }
      if (ticketClick && !disableRowClick) {
        return ticketClick(ticketInfo)
      }

      return undefined
    },
    [row, posTicketClick, ticketClick],
  )

  const showName = useCallback(
    (ticketInfo: any) => (): void => {
      console.log(ticketInfo)
    },
    [row],
  )

  const callbackRef = useCallback(
    inputElement => {
      if (inputElement) {
        setTimeout(() => {
          inputElement.focus()
        }, 0)
      }
    },
    [status],
  )

  const updateCheckedRows = (
    key: 'userid' | 'accountunitid',
    id: string,
    selectedRows: string[],
  ): void => {
    const finalArray: string[] = rows.map((obj: any) => obj.item[key])
    const unique = [...new Set(finalArray)]
    const rowArr = [...selectedRows]

    const index = rowArr.indexOf(id)

    index !== -1 ? rowArr.splice(index, 1) : rowArr.push(id)

    const isEveryChecked = unique.every(item =>
      isSelectAll ? !rowArr.includes(item) : rowArr.includes(item),
    )
    const isPartialChecked = unique.some((item: string) => rowArr.includes(item))

    setIsIndeterminate(
      isPartialChecked && (isSelectAll ? !unique.every(id => rowArr.includes(id)) : true),
    )
    setCheckedGroup(isEveryChecked)
  }

  const handleCheckRow = useCallback(
    (value: { [key: string]: any }): void => {
      handleCheckedRows && handleCheckedRows(value)
      handleCheckedObjectRows && handleCheckedObjectRows(value, false)

      if (selectedSecurityNetworksRows) {
        updateCheckedRows('userid', value.item.userid, selectedSecurityNetworksRows)
      }
      if (selectedSecurityObjectRows) {
        updateCheckedRows('accountunitid', value.item.accountunitid, selectedSecurityObjectRows)
      }
    },
    [
      handleCheckedRows,
      handleCheckedObjectRows,
      row,
      rows,
      selectedSecurityNetworksRows,
      selectedSecurityObjectRows,
    ],
  )

  const editCellHandler = (event: any, id: string, value: string) => {
    event.preventDefault()
    event.stopPropagation()
    if (status && status !== id) {
      statusClicker(null)
    } else {
      changeNote(value)
      statusClicker(id)
    }
  }

  const saveCellHandler = (
    event: any,
    fieldId: string,
    fieldValue: string,
    rowId: string,
    rowIndex: number,
    parentIndex: number,
    nesting: number,
  ): void => {
    if (fieldValue !== event.target.value) {
      dispatch(
        editTableCellRequest({
          fieldId,
          fieldValue: event.target.value,
          rowId,
          rowIndex,
          parentIndex,
          nesting,
        }),
      )
    }
    statusClicker('')
  }
  const changeNotesInput = (event: any): void => {
    changeNote(event.target.value)
  }
  const renderText = useCallback(
    (name: string, columnIndex: number) => {
      if (incomingHeaders !== undefined) {
        const index = incomingHeaders.findIndex((el: any) => el.fieldName === name)
        if (
          incomingHeaders[index] !== undefined &&
          incomingHeaders[index].fieldType === 'DATETIME'
        ) {
          return (
            <Typography variant="body2">
              {row.item[name] !== null && row.item[name].length > 0
                ? moment(row.item[name]).format(DEFAULT_DATE_TIME_SEC_FORMAT)
                : row.item[name]}
            </Typography>
          )
        } else {
          return (
            <Typography variant="body2">
              {row.item[name]}{' '}
              {onItemEditClick && columnIndex === 0 && (
                <span style={{ verticalAlign: 'middle' }}>
                  <EditIcon
                    onClick={e => {
                      e.stopPropagation()
                      onItemEditClick(row)
                    }}
                    className={classes.actionIcon}
                  />
                </span>
              )}
            </Typography>
          )
        }
      } else {
        console.log('--- !!! check why incomingHeaders is undefined !!!')
        return <Typography variant="body2">{row.item[name]}</Typography>
      }
    },
    [row],
  )
  return (
    <>
      <StyledTableRow
        onClick={handleRowClick(row.childItems && !!row.childItems.length ? row : row.item)}
        className={clsx({
          [classes.clickable]: (posTicketClick || ticketClick) && !disableRowClick,
          [classes.dangerRow]: row.item.reaction_date?.length === 0,
          [classes.rowWrap]: row.item.reaction_date?.length > 0,
        })}
      >
        {headers.map((key: string, index: number) => {
          if (key === 'docnr') {
            return (
              <TableCell
                key={headers[index]}
                align="left"
                className={classes.cell}
                style={{ paddingLeft: nesting > 1 ? `${40 * nesting}px` : `` }}
              >
                <div
                  className={clsx(classes.wrapper, {
                    [classes.withBlueColor]: (posTicketClick || ticketClick) && !disableRowClick,
                    [classes.withAdditionalPadding]: row.childItems && !row.childItems.length,
                  })}
                >
                  {row.item[key].includes('PSR') && !!row.childItems.length && (
                    <IconButton size="small" onClick={handleCollapse} className={classes.arrowIcon}>
                      {collapse ? (
                        <KeyboardArrowDownIcon className={classes.iconSize} />
                      ) : (
                        <KeyboardArrowRightIcon className={classes.iconSize} />
                      )}
                    </IconButton>
                  )}
                  <Typography variant="body2">{row.item[key]}</Typography>
                </div>
              </TableCell>
            )
          }

          if (key === 'statusname') {
            return (
              <TableCell key={headers[index]} align="left" className={classes.cell}>
                <div className={classes.wrapper}>
                  <img src={statusIconActionLogTable[row.item[key]]} />
                </div>
              </TableCell>
            )
          }

          if (checkboxColumnsNames?.includes(key)) {
            return (
              <TableCell key={headers[index]} className={classes.cell}>
                {selectedSecurityObjectRows && (
                  <Checkbox
                    onChange={(event, checked): void => {
                      event.preventDefault()
                      event.stopPropagation()
                      handleCheckRow({ ...row, [headers[index]]: checked })
                    }}
                    checked={
                      isSelectAll
                        ? selectedSecurityObjectRows.indexOf(row.item.accountunitid) < 0
                        : selectedSecurityObjectRows &&
                          selectedSecurityObjectRows.indexOf(row.item.accountunitid) >= 0
                    }
                  />
                )}
                {selectedSecurityNetworksRows && (
                  <Checkbox
                    onChange={(event, checked): void => {
                      event.preventDefault()
                      event.stopPropagation()
                      handleCheckRow({ ...row, [headers[index]]: checked })
                    }}
                    checked={
                      selectedSecurityNetworksRows &&
                      selectedSecurityNetworksRows.indexOf(row.item.userid) >= 0
                    }
                  />
                )}
              </TableCell>
            )
          }

          if (key === 'device_name') {
            return (
              <TableCell key={headers[index]} align="left" className={classes.cell}>
                <div
                  className={clsx(classes.wrapper, {
                    [classes.withBlueColor]: (posTicketClick || ticketClick) && !disableRowClick,
                    [classes.withAdditionalPadding]: row.childItems && !row.childItems.length,
                  })}
                >
                  {row.childItems && !!row.childItems.length && (
                    <IconButton size="small" onClick={handleCollapse} className={classes.arrowIcon}>
                      {collapse ? (
                        <KeyboardArrowDownIcon className={classes.iconSize} />
                      ) : (
                        <KeyboardArrowRightIcon className={classes.iconSize} />
                      )}
                    </IconButton>
                  )}
                  <Typography variant="body2">{row.item[key]}</Typography>
                </div>
              </TableCell>
            )
          }
          if (row.item.device_name === undefined && key === 'client_id') {
            return (
              <TableCell key={headers[index]} align="left" className={classes.cell}>
                <div
                  className={clsx(classes.wrapper, {
                    [classes.withBlueColor]: (posTicketClick || ticketClick) && !disableRowClick,
                    [classes.withAdditionalPadding]: row.childItems && !row.childItems.length,
                  })}
                >
                  {row.childItems && !!row.childItems.length && (
                    <IconButton size="small" onClick={handleCollapse} className={classes.arrowIcon}>
                      {collapse ? (
                        <KeyboardArrowDownIcon className={classes.iconSize} />
                      ) : (
                        <KeyboardArrowRightIcon className={classes.iconSize} />
                      )}
                    </IconButton>
                  )}
                  <Typography variant="body2">{row.item[key]}</Typography>
                </div>
              </TableCell>
            )
          }
          if (key === 'docstatus') {
            const docStatusColors = getDocStatusColor(row.item.docstatusid)

            return (
              <TableCell key={`${key}${index}`} align="left" className={classes.cell}>
                <div
                  className={clsx(classes.wrapper)}
                  style={{ background: docStatusColors.backgroundColor }}
                >
                  <DotIcon
                    className={clsx(classes.icon, classes.iconSize)}
                    style={{ color: docStatusColors.iconColor }}
                  />
                  <Typography variant="body2">{row.item[key]}</Typography>
                </div>
              </TableCell>
            )
          }
          if (key === 'devicestate') {
            const deviceStateColor = getDeviceStateColor(row.item.devicestateid)
            return (
              <TableCell key={`${key}${index}`} align="left" className={classes.cell}>
                <div className={classes.wrapper}>
                  <DotIcon
                    className={clsx(classes.icon, classes.iconSize)}
                    style={{ color: deviceStateColor }}
                  />
                  <Typography variant="body2">{row.item[key]}</Typography>
                </div>
              </TableCell>
            )
          }
          if (key === 'sladeadline' && row.item[key] !== null) {
            return (
              <TableCell key={`${key}${index}`} className={classes.cell}>
                <div className={classes.wrapper}>
                  <DotIcon
                    className={clsx(
                      classes.icon,
                      {
                        [classes.deadlineGreen]:
                          row.item.sladeadlinestateid === '1' ||
                          row.item.sladeadlinestateid === '2',
                        [classes.deadlineYellow]: row.item.sladeadlinestateid === '3',
                        [classes.deadlineRed]: row.item.sladeadlinestateid === '4',
                      },
                      classes.iconSize,
                    )}
                  />
                  <Typography variant="body2">
                    {moment(row.item[key]).format('YYYY-MM-DD HH:mm:ss')}
                  </Typography>
                </div>
              </TableCell>
            )
          }

          if (stateNames.includes(key)) {
            return (
              <TableCell key={`${key}${index}`} align="left" className={classes.cell}>
                <StyledState
                  className={clsx(classes.cellWrapper)}
                  theme={{
                    backgroundColor: row.item[`${key}_back_color`],
                    color: row.item[`${key}_main_color`],
                    data: row.item[key],
                  }}
                >
                  {row.item[key] && <DotIcon className={clsx(classes.icon, classes.iconSize)} />}
                  <Typography variant="body2">{row.item[key]}</Typography>
                </StyledState>
              </TableCell>
            )
          }
          if (key === 'camera_number' && row.item[key] !== '') {
            if (
              (row.item.content_type === 'JPG' || row.item.content_type === 'AVI') &&
              row.item.event_media_type !== 'None'
            ) {
              return (
                <TableCell
                  key={`${key}${index}`}
                  className={clsx(classes.cell, classes.clickableCell, classes.cameraCell)}
                >
                  {row.item.content_type && (
                    <FolderIconSvg
                      src={row.item.content_type === 'JPG' ? PhotoCamera : VideoCamera}
                      className={classes.cameraIcon}
                      alt="camera_icon"
                    />
                  )}
                  <Typography variant="body2">{row.item[key]}</Typography>
                </TableCell>
              )
            } else {
              return (
                <TableCell onClick={showName(key)} key={`${key}${index}`} className={classes.cell}>
                  {renderText(key, index)}
                </TableCell>
              )
            }
          }
          if (
            incomingHeaders &&
            incomingHeaders[index] !== undefined &&
            incomingHeaders[index].fieldValueMetadata !== null &&
            incomingHeaders[index].fieldValueMetadata.length > 0 &&
            (incomingHeaders[index].fieldValueMetadata[0].fieldMetadataType === 'EDITABLE_CHILD' ||
              incomingHeaders[index].fieldValueMetadata[0].fieldMetadataType === 'EDITABLE_PARENT')
          ) {
            return (
              <TableCell
                key={`${key}${index}`}
                className={classes.cell}
                onDoubleClick={event =>
                  row.item.event_id && editCellHandler(event, row.item.event_id, row.item[key])
                }
              >
                {status === row.item.event_id ? (
                  <TextField
                    value={note}
                    onChange={changeNotesInput}
                    inputRef={callbackRef}
                    variant="outlined"
                    onBlur={event =>
                      saveCellHandler(
                        event,
                        key,
                        row.item[key],
                        row.item.event_id,
                        rowIndex,
                        parentIndex,
                        nesting,
                      )
                    }
                  />
                ) : (
                  <Typography variant="body2">{row.item[key]}</Typography>
                )}
              </TableCell>
            )
          }
          return (
            <TableCell onClick={showName(key)} key={`${key}${index}`} className={classes.cell}>
              {renderText(key, index)}
            </TableCell>
          )
        })}
      </StyledTableRow>
      <Collapse isOpen={collapse}>
        {row.childItems &&
          !!row.childItems.length &&
          row.childItems.map((child: any, index: number) => (
            <ItemRow
              key={child.docnr}
              row={{ item: child, childItems: [] }}
              rows={row.childItems}
              rowIndex={index}
              parentIndex={rowIndex}
              headers={headers}
              order={order}
              orderBy={orderBy}
              nesting={nesting + 1}
              posTicketClick={posTicketClick}
              ticketClick={ticketClick}
              editTableCellRequest={editTableCellRequest}
              incomingHeaders={incomingHeaders}
              disableRowClick={disableRowClick}
              setCheckedGroup={setCheckedGroup}
              selectableTable={selectableTable}
              checkboxColumnsNames={checkboxColumnsNames}
              handleCheckedRows={handleCheckedRows}
              handleCheckedObjectRows={handleCheckedObjectRows}
              selectedSecurityObjectRows={selectedSecurityObjectRows}
              selectedSecurityNetworksRows={selectedSecurityNetworksRows}
            />
          ))}
      </Collapse>
    </>
  )
}

export default ItemRow
